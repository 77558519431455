.main-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.main-title {
  font-size: 2rem; /* Taille équivalente à text-6xl */
  font-weight: bold; /* Gras */
  margin-top: 1rem; /* Marge supérieure */
  animation: pulse 1.5s infinite; /* Animation de pulsation */
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
