.avatar-container {
  width: 16rem; /* Taille équivalente à w-64 */
  height: 16rem; /* Taille équivalente à h-64 */
  border-radius: 50%; /* Forme ronde */
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem; /* Marge inférieure équivalente à mb-8 */
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.avatar-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.avatar-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
