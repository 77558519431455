.header {
  padding: 1.5rem;
  text-align: center;
  z-index: 10;
}

.header-title {
  font-size: 3rem; /* Taille équivalente à text-5xl */
  font-weight: bold; /* Gras */
  margin-bottom: 0.5rem; /* Marge inférieure équivalente à mb-2 */
  letter-spacing: 0.05em; /* Espacement des lettres équivalent à tracking-wider */
}

.header-subtitle {
  font-size: 1.5rem; /* Taille équivalente à text-2xl */
  font-style: italic; /* Italique */
}
