@import url('https://fonts.googleapis.com/css2?family=Open+Dyslexic&display=swap');

/* Déclaration de la police OpenDyslexic */
@font-face {
  font-family: 'OpenDyslexic';
  src:
    url('/src/assets/fonts/OpenDyslexic-Regular.woff') format('woff'),
    url('/src/assets/fonts/OpenDyslexic-Bold.woff') format('woff'),
    url('/src/assets/fonts/OpenDyslexic-Bold-Italic.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

/* Utilisation de la police dans le corps du document */
body {
  font-family: 'OpenDyslexic', Arial, sans-serif;
  background: linear-gradient(135deg, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 30s ease infinite;
  color: #333;
  margin: 0;
  padding: 0;
  height: 100%;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.floating-icon {
  position: absolute;
  font-size: 2rem;
  color: white;
  animation: float 5s ease-in-out infinite;
  transition: transform 0.3s ease;
  pointer-events: auto;
  z-index: -1;
}

@keyframes float {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  75% {
    transform: translate(10px, 10px);
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.footer {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  color: #333;
  padding: 0.125rem 0;
  font-size: 0.875rem;
  font-family: 'OpenDyslexic', Arial, sans-serif;
  text-align: center;
  border-radius: 10px 10px 0 0;
  flex-shrink: 0;
}

.footer-container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0 1rem;
}

@media screen and (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;
    margin: 0.5rem 0; /* Ajout de marges pour espacer les éléments */
  }

  .header-subtitle {
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }

  .main-title {
    font-size: 1.75rem;
    margin: 1rem 0;
  }

  .avatar-container {
    width: 12rem;
    height: 12rem;
    margin-bottom: 1.5rem; /* Ajustement de la marge inférieure */
  }

  .footer-container {
    width: 100%;
    padding: 0 0.5rem;
  }

  .footer-copyright {
    font-size: 0.875rem;
  }

  .footer-social-icons {
    gap: 0.5rem;
  }

  .footer-social-link {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
  }

  .footer-logo {
    height: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .header-title {
    font-size: 2rem;
    margin: 0.5rem 0;
  }

  .header-subtitle {
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  .main-title {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .avatar-container {
    width: 10rem;
    height: 10rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-logo-copyright {
    flex-direction: row;
    gap: 0.5rem;
    text-align: left;
  }
}

.footer-logo-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  text-align: center;
}

.footer-logo {
  height: 1.5rem;
}

.footer-copyright {
  font-size: 1rem;
  opacity: 0.9;
  line-height: 1;
}

.footer-social-icons {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.footer-social-link {
  color: #333;
  font-size: 1.25rem;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  position: relative;
}

.footer-social-link:hover {
  color: white;
  transform: translateY(-3px) scale(1.15);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.footer-social-link::before {
  content: attr(aria-label);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.footer-social-link::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(4px);
  border: 4px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.footer-social-link:hover::before,
.footer-social-link:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.footer-nav {
  margin-top: 1rem;
}

.footer-nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.footer-nav-link {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  transition: background 0.3s, color 0.3s;
}

.footer-nav-link:hover,
.footer-nav-link:focus {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
}

.footer-name {
  font-weight: bold;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

#space-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}
